import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";

const AntiBriberyPolicy = () => {
  useEffect(() => {
    goToTop();
  }, []);

  return (
    <>
      <CustomHelmet
        title={`Anti-bribery commitment | EA Foods`}
        metaDescription={`We aggregate demand and deliver wide range of fresh and exotic produce directly from farms to stores of B2B customers.`}
        imagePath={metaImage}
      />

      <section
        className="hero-banner position-relative"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/images/heroabout.jpg"
          )})`,
        }}
      >
        <div className="hero-overlay position-absolute"></div>
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col xs={12} md={7} lg={6}>
                <div className="py-4">
                  <h1 className="herotitle">Anti-bribery commitment</h1>
                  <p className="mb-0 font-20">
                    Formalize their anti-bribery commitment to be published on
                    the website
                  </p>
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <img
          src={toAbsoluteUrl("/media/images/heroAboutM.jpg")}
          alt=""
          className="img-fluid hero-responsive d-block d-md-none"
        />
      </section>

      <section className="section-padding pb-0">
        <Container className="container-xl" fluid>
          <div className={`title text-center`}>
            <Container>
              <Row>
                <Col lg={8} className="mx-auto">
                  <h2>{"External communication and grievance mechanism"}</h2>

                  <KTSVG
                    path="/media/images/title_leaf.svg"
                    className="text-success w-auto mb-10px"
                  />

                  <p className="">
                    EA Foods shall develop a community grievance mechanism
                    whereby the public grievances shall be heard, recorded and
                    resolved. The community shall be informed of the mechanism
                    and it shall be readily understandable, accessible an
                    culturally appropriate. The company shall also assure the
                    community that there will be no costs or retribution
                    associated with lodging a grievance.
                  </p>
                  <p className="mb-5">
                    The company shall also maintain a log of the grievances
                    recorded and how they were resolved. These shall also be
                    publicized to the community
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(AntiBriberyPolicy);
