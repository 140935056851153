import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { NavLink as NLink, useLocation } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import Topbar from "./topbar";

const Header = (props: any) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [isActiveAbout, setIsActiveAbout] = useState<boolean>(false);
  const [isActiveHome, setIsActiveHome] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsToggle(!isToggle);
  };

  useEffect(() => {
    if (["/investors-partners", "/about"].includes(location.pathname)) {
      setIsActiveAbout(true);
    }

    if (["/testimonials"].includes(location.pathname)) {
      setIsActiveHome(true);
    }
  }, []);

  return (
    <header className="App-header fixed-top bg-white">
      <div>
        <div className="d-none d-lg-block">
          <Topbar />
        </div>
        <Navbar {...props} expand="lg" light className="py-0" container="xl">
          <NavbarBrand tag={NLink} to="/">
            <img src={toAbsoluteUrl("/media/logos/logo_eafruits.png")} alt="" />
          </NavbarBrand>
          <NavbarToggler
            onClick={toggle}
            className={isOpen ? "navbar-toggler  active" : "navbar-toggler"}
          >
            <span className=" icon-bar top-bar"></span>
            <span className=" icon-bar middle-bar"></span>
            <span className=" icon-bar bottom-bar"></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-lg-auto" navbar>
              <NavItem>
                <NavLink
                  tag={NLink}
                  to="/"
                  className={`${isActiveHome ? "active" : ""}`}
                  current="true"
                >
                  Home
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={`${isActiveAbout ? "active" : ""}`}
                >
                  About
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={NLink} to="/about">
                    About EA Foods
                  </DropdownItem>
                  {/* <DropdownItem tag={NLink} to='/about' >Why EA Foods</DropdownItem> */}
                  <DropdownItem tag={NLink} to="/anti-bribery-policy">
                    Anti Bribery Policy
                  </DropdownItem>
                  <DropdownItem tag={NLink} to="/investors-partners">
                    Investors & Partners
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  tag={NLink}
                  to="/farmer-services"
                  className={clsx(
                    `${location.pathname === "/customer-services" && "active"}`
                  )}
                >
                  Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/product">
                  Products
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/blog">
                  Blog
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/career">
                  Careers
                </NavLink>
              </NavItem>
            </Nav>
            <div className="d-block d-lg-none mt-auto w-100">
              <Topbar />
            </div>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
